import React from 'react';

import { Refine } from '@pankod/refine-core';
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  ReadyPage,
  ErrorComponent,
} from '@pankod/refine-mui';
import { customTheme } from './theme';

import dataProvider from '@pankod/refine-simple-rest';

import routerProvider from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';
import { Title, Layout, Header } from 'components/layout';

import { MainScreen } from 'pages/MainScreen';

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
      <RefineSnackbarProvider>
        <Refine
          dataProvider={dataProvider('https://citizen.kbdev.co/api')}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          resources={[
            {
              name: 'requests',
              list: MainScreen,
              create: MainScreen,
            },
          ]}
          Title={Title}
          Layout={Layout}
          Header={Header}
          routerProvider={routerProvider}
          i18nProvider={i18nProvider}
        />
      </RefineSnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
