import React from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import logo01 from '../assets/logos/logo01.svg';
import logo02 from '../assets/logos/logo02.svg';
import logo03 from '../assets/logos/logo03.svg';
import logo04 from '../assets/logos/logo04.svg';
import { Box } from '@pankod/refine-mui';

SwiperCore.use([Navigation, Pagination, Autoplay]);
const images = [
  logo01,
  logo02,
  logo03,
  logo04,
  logo01,
  logo02,
  logo03,
  logo04,
  logo02,
  logo03,
  logo04,
];
export const ImageSwiper = () => {
  return (
    <Box
      sx={{
        height: 200,
        width: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        // navigation
        // pagination={{ clickable: true }}
        autoplay={{ delay: 1000 }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Slide ${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
