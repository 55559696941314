import React from 'react';

import { AppBar, Stack } from '@pankod/refine-mui';
import logo_polyrec from '../../../assets/logo_polyrec.svg';

export const Header: React.FC = () => {
  return (
    <AppBar
      color="default"
      position="sticky"
      elevation={0}
      sx={{
        height: 90,
        backgroundColor: '#fff',
        borderBottomRightRadius: '50px',
        borderBottomLeftRadius: '50px',
        width: '100%',
        py: 4,
      }}
    >
      <Stack
        direction="row"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <div style={{ width: 160 }}>
          <img src={logo_polyrec} alt="Polyrec Logo" />
        </div>
      </Stack>
    </AppBar>
  );
};
